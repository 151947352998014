<template>
    <div>
        <!-- Title and Logo -->
        <h-retribusi />
        <!-- end Logo and Title -->

        <!-- Panel start here -->
        <panel title="Detail Data Pengguna" class="panel panel-success">
            <div class="py-3">
                <b-card class="shadow-sm">
                    <form
                        @submit.prevent="update(user.pegawai_id)"
                        @keydown="form.onKeydown($event)"
                        autocomplete="off"
                    >
                        <b-row>
                            <b-col>
                                <h4>
                                    Identitas Pengguna
                                </h4>
                            </b-col>
                            <b-col>
                                <span class="float-right">
                                    <!-- <b-button
                                        variant="primary"
                                        @click="editModeFunc"
                                    >
                                        <div v-if="!editMode">
                                            <i class="fa fa-edit"></i> Edit
                                        </div>
                                        <div v-else>
                                            <i class="fa fa-redo-alt"></i> Undo
                                        </div>
                                    </b-button> -->
                                    <span class="mx-2">
                                        <b-button
                                            variant="primary"
                                            @click="gantiPass"
                                        >
                                            <i class="fa fa-lock"></i> Ganti
                                            Kata Sandi
                                        </b-button>
                                    </span>
                                </span>
                            </b-col>
                        </b-row>
                        <b-row class="my-3">
                            <b-col>
                                <div>
                                    <label class="font-weight-bold" for="nik">
                                        Nomor Induk Kependudukan:
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            v-model="form.nik"
                                            class="form-control"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div class="my-2">
                                    <label class="font-weight-bold" for="nama">
                                        Nama:
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            v-model="form.nama"
                                            class="form-control"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div class="my-2">
                                    <label
                                        class="font-weight-bold"
                                        for="tempat"
                                    >
                                        Tempat Lahir:
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            v-model="form.tmp_lahir"
                                            class="form-control"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div class="my-2">
                                    <label class="font-weight-bold" for="unit">
                                        Unit:
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            v-model="userData.unit"
                                            class="form-control"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </b-col>
                            <b-col>
                                <div>
                                    <label class="font-weight-bold" for="nip">
                                        Nomor Induk Pegawai:
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            v-model="form.nip"
                                            class="form-control"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div class="my-2">
                                    <label class="font-weight-bold" for="jenis">
                                        Jenis Kelamin:
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            v-model="form.jenis_kelamin"
                                            class="form-control"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div class="my-2">
                                    <label
                                        class="font-weight-bold"
                                        for="tanggal"
                                    >
                                        Tanggal Lahir:
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            v-model="form.tgl_lahir"
                                            class="form-control"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div class="my-2">
                                    <label class="font-weight-bold" for="nama">
                                        Perangkat:
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            v-model="userData.perangkat"
                                            class="form-control"
                                            disabled
                                        />
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                        <h4>
                            Kontak & Alamat
                        </h4>
                        <b-row>
                            <b-col>
                                <div class="my-2">
                                    <label class="font-weight-bold" for="nomp">
                                        Nomor Handphone:
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            v-model="form.nomp"
                                            class="form-control"
                                            :disabled="isDisable"
                                        />
                                    </div>
                                </div>
                                <div class="my-2">
                                    <label class="font-weight-bold" for="email">
                                        Email:
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            v-model="form.email"
                                            class="form-control"
                                            disabled
                                        />
                                    </div>
                                </div>
                                <div class="my-2" v-if="editMode">
                                    <label
                                        class="font-weight-bold"
                                        for="notelepon"
                                    >
                                        Kab./Kota:
                                    </label>
                                    <div>
                                        <v-select
                                            label="nama"
                                            :reduce="(nama) => nama.id"
                                            :options="WilayahIndonesia.kabkota"
                                            v-model="wilayah.kabkota"
                                            placeholder="-Pilih Kab./Kota-"
                                            @input="
                                                inputKabkota(wilayah.kabkota)
                                            "
                                        ></v-select>
                                    </div>
                                </div>
                                <div class="my-2" v-if="editMode">
                                    <label
                                        class="font-weight-bold"
                                        for="notelepon"
                                    >
                                        Kelurahan:
                                    </label>
                                    <div>
                                        <v-select
                                            label="nama"
                                            :reduce="(nama) => nama.id"
                                            :options="
                                                WilayahIndonesia.kelurahan
                                            "
                                            v-model="form.wilayah_kelurahan_id"
                                            placeholder="-Pilih Kelurahan-"
                                        ></v-select>
                                    </div>
                                </div>
                            </b-col>
                            <b-col>
                                <div class="my-2">
                                    <label
                                        class="font-weight-bold"
                                        for="alamat"
                                    >
                                        Alamat:
                                    </label>
                                    <div v-if="!editMode">
                                        <b-form-textarea
                                            id="textarea"
                                            rows="6"
                                            v-model="userData.alamatText"
                                            :disabled="isDisable"
                                        ></b-form-textarea>
                                    </div>
                                    <div v-else>
                                        <input
                                            type="text"
                                            v-model="form.alamat"
                                            class="form-control"
                                        />
                                    </div>
                                </div>
                                <div class="my-2" v-if="editMode">
                                    <label
                                        class="font-weight-bold"
                                        for="notelepon"
                                    >
                                        Provinsi:
                                    </label>
                                    <div>
                                        <v-select
                                            label="nama"
                                            :reduce="(nama) => nama.id"
                                            :options="WilayahIndonesia.provinsi"
                                            v-model="wilayah.provinsi"
                                            @input="
                                                inputProvinsi(wilayah.provinsi)
                                            "
                                            placeholder="-Pilih Provinsi-"
                                        ></v-select>
                                        <em
                                            v-if="
                                                form.errors.has(
                                                    'wilayah_kelurahan_id'
                                                )
                                            "
                                            class="form-text text-danger"
                                            ><i
                                                class="fa fa-exclamation-triangle"
                                            ></i>
                                            Isian Kecamatan Wajib Diisi.
                                        </em>
                                    </div>
                                </div>
                                <div class="my-2" v-if="editMode">
                                    <label
                                        class="font-weight-bold"
                                        for="notelepon"
                                    >
                                        Kecamatan:
                                    </label>
                                    <div>
                                        <v-select
                                            label="nama"
                                            :reduce="(nama) => nama.id"
                                            :options="
                                                WilayahIndonesia.kecamatan
                                            "
                                            v-model="wilayah.kecamatan"
                                            placeholder="-Pilih Kecamatan-"
                                            @input="
                                                inputKecamatan(
                                                    wilayah.kecamatan
                                                )
                                            "
                                        ></v-select>
                                        <em
                                            v-if="
                                                form.errors.has(
                                                    'wilayah_kelurahan_id'
                                                )
                                            "
                                            class="form-text text-danger"
                                            ><i
                                                class="fa fa-exclamation-triangle"
                                            ></i>
                                            Isian Kecamatan Wajib Diisi.
                                        </em>
                                    </div>
                                </div>
                                <div class="my-2" v-if="editMode">
                                    <label
                                        class="font-weight-bold"
                                        for="notelepon"
                                    >
                                        Kodepos:
                                    </label>
                                    <div>
                                        <input
                                            type="text"
                                            v-model="form.kodepos"
                                            class="form-control"
                                        />
                                        <em
                                            v-if="form.errors.has('kodepos')"
                                            class="form-text text-danger"
                                            ><i
                                                class="fa fa-exclamation-triangle"
                                            ></i>
                                            {{ errors.kodeposs[0] }}</em
                                        >
                                    </div>
                                </div>
                            </b-col>
                        </b-row>
                        <div class="float-right mt-3" v-if="editMode">
                            <b-button variant="primary" type="submit">
                                <i class="fa fa-save"></i> Simpan Perubahan
                            </b-button>
                        </div>
                    </form>
                </b-card>
            </div>
        </panel>
        <!-- Panel end here -->
        <!-- Modal Data --->
        <b-modal
            title="Ganti Kata Sandi"
            v-model="showModalForm"
            id="modal"
            hide-footer
            no-close-on-backdrop
            size="md"
        >
            <form
                @submit.prevent="store"
                @keydown="form.onKeydown($event)"
                autocomplete="off"
            >
                <div class="form-group row mb-1">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Password Lama <span class="text-danger">*</span></label
                    >
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-sm-12">
                                <input
                                    type="password"
                                    :class="{'form-control': true, 'is-invalid': pass.errors.has('current_password')}"
                                    placeholder="Masukkan Password Lama"
                                    v-model="pass.current_password"
                                />
                                <em
                                    v-if="
                                        pass.errors.has('current_password')
                                    "
                                    class="form-text text-danger"
                                    ><i class="fa fa-exclamation-triangle"></i>
                                    {{ errors.current_password[0] }}</em
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Password <span class="text-danger">*</span></label
                    >
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-sm-12">
                                <input
                                    type="password"
                                    :class="{'form-control': true, 'is-invalid': pass.errors.has('password')}"
                                    placeholder="Masukkan Password Baru"
                                    v-model="pass.password"
                                />
                                <em
                                    v-if="pass.errors.has('password')"
                                    class="form-text text-danger"
                                    ><i class="fa fa-exclamation-triangle"></i>
                                    {{ errors.password[0] }}</em
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="form-group row mb-1">
                    <label class="col-md-4 col-form-label font-weight-bold"
                        >Konfirmasi Password
                        <span class="text-danger">*</span></label
                    >
                    <div class="col-md-8">
                        <div class="row">
                            <div class="col-sm-12">
                                <input
                                    type="password"
                                    :class="{'form-control': true, 'is-invalid': pass.errors.has('password')}"
                                    placeholder="Konfirmasi Password"
                                    v-model="pass.password_confirmation"
                                />
                                <em
                                    v-if="pass.errors.has('password_confirmation')"
                                    class="form-text text-danger"
                                    ><i class="fa fa-exclamation-triangle"></i>
                                    {{ errors.password_confirmation[0] }}</em
                                >
                            </div>
                        </div>
                    </div>
                </div>
                <div class="mt-4 float-right">
                    <b-button variant="primary" type="submit">
                        <i class="fa fa-edit"></i> Rubah Kata Sandi
                    </b-button>
                </div>
            </form>
        </b-modal>
    </div>
</template>

<script>
/* eslint-disable */
// eslint-disable-next-line
// eslint-disable vue/no-unused-vars
// eslint-disable-line no-unused-vars
import HRetribusi from "@/components/header/HRetribusi.vue";
import hapusStorage from "@/helper/hapusLocalStore";
import axios from "axios";
import { mapState } from "vuex";

export default {
    mixins: [hapusStorage],
    components: { HRetribusi },
    data() {
        return {
            showModalForm: false,
            form: new Form({
                nik: "",
                nip: "",
                nama: "",
                nomp: "",
                jenis_kelamin: "",
                email: "",
                alamat: "",
                wilayah_kelurahan_id: "",
                kodepos: "",
                tmp_lahir: "",
                tgl_lahir: "",
                unit_pd_id: "",
                perangkat_pd_id: "",
            }),
            userData: {
                alamatText: "",
                unit: "",
                perangkat: "",
            },
            user: JSON.parse(localStorage.getItem("user")),
            isDisable: true,
            editMode: false,
            pass: new Form({
                current_password: "",
                password: "",
                password_confirmation: "",
            }),
            errors: [],
            wilayah: {
                provinsi: "",
                kabkota: "",
                kecamatan: "",
            },
        };
    },
    computed: {
        ...mapState(["WilayahIndonesia"]),
    },
    mounted() {
        this.$store.dispatch("WilayahIndonesia/getProvinsi");
        this.detailPegawai(this.user.pegawai_id);
    },
    methods: {
        detailPegawai(id) {
            axios
                .get("/api/data-induk/kepegawaian/pegawai/" + id)
                .then((response) => {
                    const items = response.data.data;
                    this.form.fill(items);
                    this.userData.unit = items.unit_pd.nama;
                    this.userData.perangkat = items.unit_pd.perangkat_pd.nama;
                    this.wilayah.provinsi =
                        items.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota.wilayah_provinsi_id;
                    this.$store.dispatch(
                        "WilayahIndonesia/getKabkota",
                        this.wilayah.provinsi
                    );
                    this.wilayah.kabkota =
                        items.wilayah_kelurahan.wilayah_kecamatan.wilayah_kabkota_id;
                    this.$store.dispatch(
                        "WilayahIndonesia/getKecamatan",
                        this.wilayah.kabkota
                    );
                    this.wilayah.kecamatan =
                        items.wilayah_kelurahan.wilayah_kecamatan_id;
                    this.$store.dispatch(
                        "WilayahIndonesia/getKelurahan",
                        this.wilayah.kecamatan
                    );
                    this.userData.alamatText =
                        items.alamat +
                        "\nKel. " +
                        items.wilayah_kelurahan.nama +
                        "\nKec. " +
                        items.wilayah_kelurahan.wilayah_kecamatan.nama +
                        "\n" +
                        items.wilayah_kelurahan.wilayah_kecamatan
                            .wilayah_kabkota.nama +
                        "\n" +
                        items.wilayah_kelurahan.wilayah_kecamatan
                            .wilayah_kabkota.wilayah_provinsi.nama +
                        "\n" +
                        items.kodepos;
                })
                .catch((error) => {
                    if (error.response.status === 401) {
                        // HAPUS storage mixins
                        this.clearAll();
                    }
                });
        },

        editModeFunc() {
            if (!this.editMode) {
                this.editMode = true;
                this.isDisable = false;
            } else {
                this.editMode = false;
                this.isDisable = true;
            }
        },
        gantiPass() {
            this.pass.reset()
            this.pass.clear()
            this.$root.$emit("bv::show::modal", "modal");
            this.showModalForm == true;
            this.errors = []
        },

        store() {
            this.pass
                .put("/api/manajemen/user/ganti-password")
                .then((response) => {
                    this.pass.reset()
                    this.pass.clear()
                    this.$swal({
                        title: "Password Berhasil Dirubah.",
                        text: "Anda dapat login dengan password yang baru",
                        icon: "success",
                        showCloseButton: true,
                        timer: 5000,
                    });
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                    }
                });
        },
        //Wilayah
        // input provinsi
        inputProvinsi(id) {
            if (id) {
                this.$store.dispatch("WilayahIndonesia/getKabkota", id);
                this.wilayah.kabkota = "";
                this.wilayah.kecamatan = "";
                this.form.wilayah_kelurahan_id = "";
                this.$store.commit("WilayahIndonesia/kecamatan", []);
                this.$store.commit("WilayahIndonesia/kelurahan", []);
                this.$store.commit("WilayahIndonesia/kabkota", []);
            } else {
                this.$store.commit("WilayahIndonesia/kabkota", []);
                this.$store.commit("WilayahIndonesia/kecamatan", []);
                this.$store.commit("WilayahIndonesia/kelurahan", []);
                this.wilayah.kabkota = "";
                this.wilayah.kecamatan = "";
            }
        },
        inputKabkota(id) {
            if (id) {
                this.$store.dispatch("WilayahIndonesia/getKecamatan", id);
                this.wilayah.kecamatan = "";
                this.form.wilayah_kelurahan_id = "";
                this.$store.commit("WilayahIndonesia/kecamatan", []);
                this.$store.commit("WilayahIndonesia/kelurahan", []);
            } else {
                this.$store.commit("WilayahIndonesia/kecamatan", []);
                this.$store.commit("WilayahIndonesia/kelurahan", []);
                this.wilayah.kecamatan = "";
                this.form.wilayah_kelurahan_id = "";
            }
        },
        inputKecamatan(id) {
            if (id) {
                this.$store.dispatch("WilayahIndonesia/getKelurahan", id);
                this.form.wilayah_kelurahan_id = "";
            } else {
                this.$store.commit("WilayahIndonesia/kelurahan", []);
                this.form.wilayah_kelurahan_id = "";
            }
        },

        // update data pegawai
        update(id) {
            this.form
                .put("/api/data-induk/kepegawaian/pegawai/" + id)
                .then((response) => {
                    this.$swal({
                        title: "Data Berhasil Dirubah.",
                        text: "Anda dapat login dengan password yang baru",
                        icon: "success",
                        showCloseButton: true,
                        timer: 5000,
                    });
                })
                .catch((error) => {
                    if (error.response.status === 422) {
                        this.errors = error.response.data;
                    }
                });
        },
    },
};
</script>
